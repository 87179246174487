import { useCallback, useEffect, useState } from "react";
import QRCodeComponent from "react-qr-code";
import { Box, Button, Text } from "~/ui";
import { COMMUNITY_GROUP_QUERY_PARAM_KEY, InitialIdentity } from "../constants";
import { useDisplayProperties } from "~/api";
import { useIdentityContext } from "./hooks";
import { shuffle } from "lodash";
import { buildRoute } from "~/config";
import { Kaleidoscope } from "~/kaleidoscope";
import { encodeDisplayProperties } from "~/urls";

const TIMEOUT_SECONDS = 60;

export function FinalScreen() {
  const { setIdentity, setScreenState } = useIdentityContext();
  const [remainingSeconds, setRemainingSeconds] = useState(TIMEOUT_SECONDS);
  const { identity } = useIdentityContext();
  const displayProperties = useDisplayProperties(false);
  const [qrUrl, setQRUrl] = useState<string>();

  const secondsCopy =
    remainingSeconds > 1 ? `${remainingSeconds} seconds` : "1 second";

  const resetExperience = useCallback(() => {
    setIdentity(InitialIdentity);
    setScreenState("Intro");
    window.location.reload();
  }, [setIdentity, setScreenState]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingSeconds((val) => val - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (remainingSeconds < 0) {
      resetExperience();
    }
  }, [resetExperience, remainingSeconds]);

  useEffect(() => {
    const communityGroupIds = identity.attributes.flatMap(
      (a) => a.attribute.communityId ?? []
    );

    const communityGroupId = communityGroupIds.length
      ? shuffle(communityGroupIds)[0]
      : undefined;

    const generateUrl = () => {
      const base = `${window.location.origin}${buildRoute("/resources")}`;

      if (!displayProperties) {
        return;
      }

      // TODO: URL could be smaller with truncated keys
      const communityId = communityGroupId ? communityGroupId.toString() : "";

      const params: URLData = {
        key: encodeDisplayProperties(displayProperties),
        [COMMUNITY_GROUP_QUERY_PARAM_KEY]: communityId,
        e: identity?.employmentStatus === "external" ? "1" : "0",
      };

      const searchParams = new URLSearchParams(params).toString();

      return `${base}?${searchParams}`;
    };

    const url = generateUrl();

    if (url) {
      setQRUrl(url);
    }
  }, [displayProperties, identity])

  return (
    <Box>
      <Text variant="title" style={{ textAlign: "center" }}>
        INSIDE THE GREEN DOT
      </Text>

      <Kaleidoscope small pollForUpdates={false} />

      {qrUrl &&
        <Banner url={qrUrl} />
      }

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ textTransform: "uppercase", letterSpacing: 4 }}
          onClick={resetExperience}
        >
          Done
        </Button>
      </div>
      <Text variant="caption">
        Experience will restart automatically in {secondsCopy}
      </Text>
    </Box>
  );
}

function Banner({ url }: { url: string }) {
  return (
    <div
      style={{
        backgroundColor: "rgba(256,256,256,0.9)",
        boxSizing: "border-box",
        padding: "0px 64px 0px 64px",
        marginBottom: 32,
        display: "flex",
        justifyContent: "center",
        height: 150,
      }}
    >
      <Text
        variant="h2"
        style={{
          color: "black",
          textAlign: "left",
          padding: 16,
          textTransform: "inherit",
        }}
      >
        Keep Your Unique Visualization
        <br />& Find Opportunities for Connection
      </Text>
      <div
        style={{
          backgroundColor: "white",
          width: 220,
          height: 220,
          borderRadius: 320,
          boxShadow: "2px 5px 10px rgba(0, 0, 0, 0.25)",
          marginTop: -32,
        }}
      >

        <QRCode url={url} />
      </div>
    </div>
  );
}

type ValidKey = "cg" | "key" | "e";
type URLData = Record<ValidKey, string>;

function QRCode({ url }: { url: string}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
        <QRCodeComponent value={url} size={128} />
    </div>
  );
}
