import { UserAttribute, Category, SubCategory } from "~/identities/types";
import { useCallback, useMemo, useState } from "react";

import { generateHash } from "~/hash";
import { IdentityContext, IdentityContextValues } from "./context";
import { ScreenState } from "./types";

import { useIdentityState } from "~/identities/hooks";
import { IntroScreen } from "./IntroScreen";
import { CategorySelectionScreen } from "./CategorySelectionScreen";
import { InternalExternalSelectionScreen } from "./InternalExternalSelectionScreen";
import { SubCategorySelectionScreen } from "./SubCategorySelectionScreen";
import { AttributeSelectionScreen } from "./AttributeSelectionScreen";
import { IntensitySelectionScreen } from "./IntensitySelectionScreen";
import { FinalScreen } from "./FinalScreen";
import { SessionTimerProvider } from "~/session-timer/context";

export function IdentityChooser() {
  const { identity, setIdentity } = useIdentityState();

  const [userAttribute, setUserAttribute] = useState<UserAttribute | undefined>(
    undefined
  );
  const [hash, setHash] = useState(generateHash());
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [subCategory, setSubCategory] = useState<SubCategory | undefined>(
    undefined
  );
  const [sessionStartTime, setSessionStartTime] = useState<number>(0);

  const handleEmploymentStatusSelection = useCallback(
    (value: "internal" | "external") => {
      setIdentity({
        attributes: identity.attributes,
        employmentStatus: value,
      });
    },
    [identity, setIdentity]
  );

  const resetHash = useCallback(() => {
    setHash(generateHash());
  }, [setHash]);

  const [screenState, setScreenState] = useState<ScreenState>("Intro");

  const value = useMemo<IdentityContextValues>(() => {
    return {
      identity,
      setIdentity,

      screenState,
      setScreenState,

      category,
      setCategory,

      subCategory,
      setSubCategory,

      userAttribute,
      setUserAttribute,
      handleEmploymentStatusSelection,
      hash,
      resetHash,

      sessionStartTime,
      setSessionStartTime
    };
  }, [
    handleEmploymentStatusSelection,
    resetHash,
    identity,
    setIdentity,
    category,
    subCategory,
    userAttribute,
    screenState,
    setScreenState,
    setUserAttribute,
    setCategory,
    setSubCategory,
    hash,
    sessionStartTime,
    setSessionStartTime
  ]);

  return (
    <IdentityContext.Provider value={value}>
      <SessionTimerProvider>
        {screenState === "Intro" && <IntroScreen />}
        {screenState === "InternalExternalSelection" && (
          <InternalExternalSelectionScreen />
        )}
        {screenState === "CategorySelection" && <CategorySelectionScreen />}
        {screenState === "SubCategorySelection" && (
          <SubCategorySelectionScreen />
        )}
        {screenState === "AttributeSelection" && <AttributeSelectionScreen />}
        {screenState === "IntensitySelection" && <IntensitySelectionScreen />}
        {screenState === "Final" && <FinalScreen />}
      </SessionTimerProvider>
    </IdentityContext.Provider>
  );
}
